<template>
  <div
    v-if="isActive"
    :id="anchor"
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: [0, 0.5, 1.0],
      },
    }"
    class="d-flex flex-column flex-sm-row cms-element-image-text-box"
  >
    <div class="w-full d-flex" :class="{ 'order-sm-1': imageRight }">
      <cms-util-link :url="imageLink" class="d-flex w-full">
        <video
          v-if="isVideo"
          loop
          autoplay
          playsinline
          muted
          class="cms-element-image-text-box__video"
          :poster="getProxyUrl(media)"
        >
          <source v-if="isIntersecting" :src="videoUrl" type="video/mp4" />
          <base-image :media="media" :init-height="450" :alt="mediaAlt" />
        </video>
        <base-image
          v-else
          class="cms-element-image-text-box__image"
          loading="lazy"
          :init-height="450"
          :alt="mediaAlt"
          :media="media"
        ></base-image>
      </cms-util-link>
    </div>
    <cms-element-text
      :class="textClasses"
      :content="content"
    ></cms-element-text>
  </div>
</template>

<script>
import {
  getCmsLink,
  getCmsSlotConfigProperty,
  isCmsSlotActive,
} from '~/helpers'
import CmsElementText from '~/components/cms/elements/CmsElementText'
import CmsUtilLink from '~/components/cms/util/CmsUtilLink'
import BaseImage from '~/components/base/image/BaseImage'
import { buildProxyImageUrl } from '~/helpers/proxy/buildProxyImageUrl'

export default {
  name: 'CmsElementImageTextBox',
  components: { BaseImage, CmsUtilLink, CmsElementText },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isIntersecting: false,
    }
  },
  computed: {
    isActive() {
      return isCmsSlotActive(this.content)
    },
    text() {
      return this.content.data.content
    },
    imageRight() {
      return this.content.config?.mediaRight?.value || false
    },
    media() {
      return this.content.data.media
    },
    mediaAlt() {
      return this.content.data.media?.alt
    },
    videoUrl() {
      return this.content.data?.videoMedia?.url
    },
    isVideo() {
      return !!this.videoUrl
    },
    imageLink() {
      return getCmsLink(this.content)
    },
    isTextFullCustom() {
      return !!getCmsSlotConfigProperty(this.content, 'textFullCustom')
    },
    anchor() {
      return getCmsSlotConfigProperty(this.content, 'anchor') || undefined
    },
    textClasses() {
      const cl = getCmsSlotConfigProperty(this.content, 'textCss')

      return this.isTextFullCustom
        ? cl
        : [
            cl,
            'w-full',
            'd-flex',
            'flex-column',
            'align-start',
            'justify-sm-end',
            'py-3 px-6 pa-sm-5',
          ]
    },
  },
  methods: {
    getProxyUrl(media) {
      return buildProxyImageUrl(this.$config, media?.url, { w: 450 })
    },
    onIntersect(entries) {
      this.isIntersecting =
        this.isIntersecting || entries?.[0]?.intersectionRatio >= 0.5
    },
  },
}
</script>
